import React from 'react';
import { GiSparkles } from "react-icons/gi";
import { BiHappyHeartEyes } from "react-icons/bi";
import { FaUserFriends } from "react-icons/fa";
import { GrGrow } from "react-icons/gr";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import './Home.css';

const Home: React.FC = () => (
  <div className=''>
    <section className="hero shiny-bg">
        <div className="hero__left">
        <h1><span className="highlight">Stop shouting.</span> Shape good behavior with love.</h1>
        <p>Start building better habits today! Say no to punishments and yes to positive reinforcement.</p>
        <p>Our app makes it fun for kids to learn and adhere to good habits by rewarding them with colorful achievements.</p>
        <div className="hero__buttons">
        <a href="https://apps.apple.com/vn/app/behavior-monster/id6736826702?platform=iphone" target="_blank" rel="noopener noreferrer">
            <button>iOS</button>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.behaviormonster.app" target="_blank" rel="noopener noreferrer">
            <button>Android</button>            
        </a>
        </div>
        </div>
        <div  className="hero__right">
            <img className='hero-img' src='./happy_kid_transparent3.webp'></img>
        </div>
    </section>

    <section className="benefits">
        <div className='benefits__item'>
            <BiHappyHeartEyes className='benefits__icon' />
            <h3>Promotes positive reinforcement</h3>
            <p>Instead of punishing mistakes, Behavior Monster encourages positive behavior through rewards. It's a proven method that enhances motivation and instills valuable life skills.</p>
        </div>

        <div className='benefits__item'>
            <GrGrow className='benefits__icon' />        
            <h3>Turns routines into engagement</h3>
            <p>Watch your child's eyes light up as they transform mundane tasks into exciting quests. Each task completed means coloring a part of their monster, making everyday routines delightful.</p>
        </div>

        <div className='benefits__item'>
        <FaUserFriends className='benefits__icon' />
            <h3>Strenghen parent-child bonding</h3>
            <p>Collaborate with your child to set tasks and rewards, creating not just a routine, but a shared adventure. This collaboration improves communication and mutual respect.</p>
        </div>

        <div className='benefits__item'>
            <GiSparkles className='benefits__icon' />
            <h3>Educational and fun</h3>
            <p>Beyond just tasks, your child learns about goal-setting, the value of perseverance, and the joy of achievement. Behavior Monster makes learning these skills fun and interactive.</p>
        </div>
    </section>

    

    <section className="how shiny-bg">
        <h2>How It Works</h2>
        <div className="how__step left">
            <img className='how__step__img' src='./profile.png'></img>
            <div className='how__text'>
                <h3>Set Up Profiles:</h3>
                <p>Set up individual profiles for each of your children</p>
            </div>
        </div>
        <div className="how__step right">
            <img className='how__step__img' src='./tasks.png'></img>
            <div className='how__text'>
                <h3>Choose Tasks:</h3>
                <p>Together with your kids, pick daily tasks and set meaningful rewards.</p>
            </div>
        </div>
        <div className="how__step left">
        <img className='how__step__img' src='./color.png'></img>
            <div className='how__text'>
                <h3>Color the Monster:</h3>
                <p>Every completed task lets your child color a part of their very own monster.</p>
            </div>
        </div>
        <div className="how__step right">
        <img className='how__step__img' src='./win.png'></img>
            <div className='how__text'>
                <h3>Celebrate Achievements:</h3>
                <p>When the monster is fully colored, your child wins the reward set by you. It's a fun way to motivate and teach the value of responsibility.</p>
            </div>
        </div>        
    </section>

    <section className="testimonials">
        <h2>What Users Say</h2>
        <Swiper
        spaceBetween={40}
        slidesPerView={1}
        breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        autoplay={{
            delay: 6000,
            disableOnInteraction: false,
        }}
        pagination={{
            clickable: false,
          }}
          modules={[Autoplay, Pagination]}
        >
        <SwiperSlide className="testimonial">"Life-changing! Behavior Monster has completely transformed how we handle daily tasks at home. My 6-year-old now looks forward to completing his tasks just to see his monster come to life. It's like magic! This app has made our mornings smoother and our evenings more relaxed. I can't recommend it enough!" <span className='author'>- Sarah W.</span></SwiperSlide>
        <SwiperSlide className="testimonial">"Honestly, I was skeptical at first, but Behavior Monster has proven to be a game-changer. My daughter used to resist every little task, but now she's eager to help out just to color her monster. It's amazing how something so simple can have such a big impact." <span className='author'>- Michael B.</span>.</SwiperSlide>
        <SwiperSlide className="testimonial">"We love it! My son was always reluctant to do his chores, but now he can't wait to earn his rewards. His behavior has improved so much. Highly recommend!" <span className='author'>- Jessica R.</span></SwiperSlide>
        <SwiperSlide className="testimonial">"Behavior Monster has brought so much peace into our home. My kids are motivated and excited to complete their tasks, and it's such a joy to see them proud of their achievements :)" <span className='author'>- Emily K.</span></SwiperSlide>
        <SwiperSlide className="testimonial">"I've tried so many different systems to get my kids to help out around the house, but nothing has worked as well as Behavior Monster. The gamification simply works. My kids are engaged, motivated, and actually having fun with their duties!" <span className='author'>- Daniel M.</span></SwiperSlide>
        <SwiperSlide className="testimonial">"Extremely helpful! My 7-year-old is naturally stubborn, but this app has completely turned things around. She now enjoys doing her tasks, and our household has become much more harmonious. The best part? She's learning valuable habits along the way." <span className='author'>- Lisa G.</span></SwiperSlide>
        <SwiperSlide className="testimonial">"Behavior Monster has made parenting so much easier. My son now takes pride in completing his tasks, and it's all thanks to this app. It's such a clever way to teach responsibility and build good habits!" <span className='author'>- Mark H.</span></SwiperSlide>
        <SwiperSlide className="testimonial">"Who knew that a little monster could have such a big impact? We love seeing our kids so motivated. It's fantastic!" <span className='author'>- John P.</span></SwiperSlide>
        <SwiperSlide className="testimonial">"I can't say enough good things about Behavior Monster. The concept of rewarding good behavior instead of punishing bad behavior has been a game-changer for us. My kids are happier, more cooperative, and the atmosphere at home is so much more positive. This app is a must-have for parents!" <span className='author'>- Rachel T.</span></SwiperSlide>
        <SwiperSlide className="testimonial">"We were constantly arguing with our kids to get their stuff done. Now they are excited to help at home and earn their rewards, and our mornings have become so much easier. We are so grateful!" <span className='author'>- Amanda L.</span></SwiperSlide>
        <SwiperSlide className="testimonial">"I'm absolutely in love with Behavior Monster! My kids used to argue over chores, but now they can't wait to see who gets to color their monster first. It's brought a sense of competition and fun to our household, and I couldn't be happier with the results. Thank you for this amazing app!" <span className='author'>- Sophie W.</span></SwiperSlide>
        <SwiperSlide className="testimonial">"If you're struggling to get your kids to do their chores, you need to try Behavior Monster. It's made such a difference in our family. My son is now excited to help out, and I've noticed a huge improvement in his behavior. The app is fun, easy to use, and overall effective!" <span className='author'>- David C.</span></SwiperSlide>
        </Swiper>
    </section>
  </div>
);

export default Home;